import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.showModal();
  }

  close() {
    const modal = this.element;
    modal.close();
    document.getElementById("remote_modal").innerHTML = "";
  }
}
